/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"

import Page from "../layouts/page";

const styles = css`
`

// markup
const NotFoundPage = () => {
  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <h1>Page not found!</h1>
        <p>Sorry, the requested page was not found.</p>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Page not found"
  />
)

export default NotFoundPage
